import axios from 'axios';
import config from '../config';

function apiRequestGet(token) {
  return function(params) {
    return function(url) {
      return axios.get(`${config.backendServer}${url}`, {
        params: params,
        headers: {
          authorization: `Token ${token}`,
        },
      });
    };
  };
}

function apiRequestPost(token) {
  return function(params) {
    return function(url) {
      return function(data) {
        return axios.post(`${config.backendServer}{$url}`, data, {
          params,
          headers: {
            authorization: `Token ${token}`,
          },
        });
      };
    };
  };
}

export { apiRequestGet, apiRequestPost };
