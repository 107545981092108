import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
  login,
  logout,
  tokenExists,
  getEmailToken,
} from '../../redux/actions/authentication/actionCreators';

import { growl } from '../../redux/actions/notifications/actionCreators';

import {
  allow,
  isAllowed,
} from '../../redux/actions/authorization/actionCreators';

import Styled, { ThemeProvider } from 'styled-components';
import { format } from 'date-fns';

import Layout from '../../layouts/backendLayout';
import LoginForm from '../../components/loginForm';
import LoadingSpinner from '../../primespot-ui/components/loadingSpinner';
import Card from '../../primespot-ui/components/card';
import Table from '../../primespot-ui/components/table';

import theme from '../../styles/theme';

import {
  giveawayContactsGetRecentTotal,
  giveawayContactsGetTotal,
  giveawayContactsGetAll,
  giveawayContactsGetWinner,
  giveawayContactsGetTotalWinners,
} from '../../util/resources/giveawayContacts';
import SVG from '../../primespot-ui/components/svg';
import { generateCSV, downloadCSV } from '../../util/csv';

class GiveawayContactsPage extends Component {
  state = {
    loading: true,
    totalContacts: null,
    recentTotalContacts: null,
    contacts: [],
  };

  componentDidMount() {
    this._isMounted = true;

    const token = this.props.getToken();
    Promise.all([
      giveawayContactsGetTotal(token),
      giveawayContactsGetRecentTotal(token),
      giveawayContactsGetAll(token),
      giveawayContactsGetTotalWinners(token),
    ])
      .then(res => {
        const newState = {
          totalContacts: res[0].data.total,
          recentTotalContacts: res[1].data.recentTotal,
          contacts: res[2].data.results,
          winners: res[3].data.totalWins,
          loading: false,
        };
        this._isMounted && this.setState(newState);
      })
      .catch(err => {
        console.error(
          '[Page /backend/giveaway-contacts.js] ERROR while getting data.'
        );
        console.error(err);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const headers = [
      {
        key: 'email',
        label: 'Email',
      },
      {
        key: 'registeredOn',
        label: 'Date registered',
      },
      {
        key: 'wins',
        label: 'Wins',
      },
    ];
    const rows = this.state.contacts.map(contact => ({
      email: contact.email,
      registeredOn: format(contact.createdAt, 'MMMM Do, YYYY [at] h:mm a'),
      wins: contact.wins.length || 0,
    }));

    /*
    Show small cards with key metrics: Total contacts, contacts past 30 days, winners selected.
    Each metric should have its own small card.
    Show a table of all the contacts (paginated).
    Allow a CSV download of all the contacts (not paginated).
    */
    return (
      <ThemeProvider theme={theme}>
        <Layout
          miniSidebar
          noPadding
          location={this.props.location}
          user={this.props.user}
          login={this.props.login}
          getEmailToken={this.props.getEmailToken}
          logout={this.props.logout}
          growl={this.props.growl}
        >
          <Wrapper className="giveaway-contacts-page__main-wrapper">
            {!this.props.user ||
            (this.props.user &&
              !['sales', 'admin', 'super admin'].includes(
                this.props.user.role
              )) ? (
              <LoginForm />
            ) : (
              <Fragment>
                {this.state.loading ? (
                  <LoadingSpinnerWrapper>
                    <LoadingSpinner />
                  </LoadingSpinnerWrapper>
                ) : (
                  <Fragment>
                    <h1>Giveaway Contacts</h1>
                    <div>
                      <Card inline shadow={0}>
                        <CardTitle>Total contacts</CardTitle>
                        <CardData>{this.state.totalContacts}</CardData>
                      </Card>
                      <Card inline shadow={0}>
                        <CardTitle>Contacts past 30 days</CardTitle>
                        <CardData>{this.state.recentTotalContacts}</CardData>
                      </Card>
                      <Card inline shadow={0}>
                        <CardTitle>Total wins</CardTitle>
                        <CardData>{this.state.winners}</CardData>
                      </Card>
                    </div>
                    <h2>All Contacts</h2>
                    <div className="toolbar">
                      <button
                        className="export-csv-button"
                        onClick={() => {
                          const csv = generateCSV(
                            this.state.contacts.map(contact => ({
                              email: contact.email,
                              createdAt: contact.createdAt,
                            }))
                          );
                          downloadCSV({ csv });
                        }}
                      >
                        <SVG image="download" />
                        Export CSV
                      </button>
                      <span className="toolbar__get-winner-button">
                        <button
                          onClick={() => {
                            giveawayContactsGetWinner(this.props.getToken())
                              .then(res => {
                                const button = document.querySelector(
                                  '.toolbar__get-winner-button'
                                );
                                button.innerHTML = `<span>${
                                  res.data.winner.email
                                }</span>`;
                              })
                              .catch(err => {
                                console.error(
                                  'An error occurred attempting to get a winner.',
                                  err
                                );
                              });
                          }}
                        >
                          <SVG image="gift" />
                          Get winner
                        </button>
                      </span>
                    </div>
                    <Table
                      caption="Giveaway Contacts"
                      headers={headers}
                      rows={rows}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
          </Wrapper>
        </Layout>
      </ThemeProvider>
    );
  }
}

const Wrapper = Styled.div`
  padding: 12px;
  padding-left: 24px;
  box-sizing: border-box;
  position: relative;
  height: calc(100vh - 50px);

  .toolbar svg {
    width: 18px;
    height: 18px;
    margin-right: 12px;
    fill: #555;
  }

  .toolbar button {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    border: none;
    background: none;
    cursor: pointer;
    margin-right: 18px;
    margin-bottom: 18px;
    color: #555;
    padding: 9px 12px;
    border-radius: 2px;
    font-size: 14px;
    transition: all .3s ease;

    &:hover {
      background: #DDD;
    }
  }
`;

const LoadingSpinnerWrapper = Styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CardTitle = Styled.div`
  font-size: 18px;
  margin-bottom: 12px;
`;

const CardData = Styled.div`
  font-size: 30px;
  text-align: center;
`;

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
    token: state.authentication.token,
    getToken: () => state.authentication.token,
    isLoading: state.authentication.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: ({ email, password, token }) =>
      dispatch(login({ email, password, token })),
    logout: () => dispatch(logout()),
    tokenExists: () => dispatch(tokenExists()),
    getEmailToken: () => dispatch(getEmailToken()),
    growl: ({ message, type }) => dispatch(growl({ message, type })),
    allow: (roles, resources, actions) =>
      dispatch(allow(roles, resources, actions)),
    isAllowed: (role, resources, actions) =>
      dispatch(isAllowed(role, resources, actions)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiveawayContactsPage);
