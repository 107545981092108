import { apiRequestGet } from '../restApi';

function giveawayContactsGetRecentTotal(token) {
  const apiUrl = `/giveaway-contacts/recent-total`;
  return apiRequestGet(token)()(apiUrl);
}

function giveawayContactsGetTotal(token) {
  const apiUrl = `/giveaway-contacts/total`;
  return apiRequestGet(token)()(apiUrl);
}

function giveawayContactsGetAll(token) {
  const apiUrl = `/giveaway-contacts`;
  return apiRequestGet(token)()(apiUrl);
}

function giveawayContactsGetWinner(token) {
  const apiUrl = `/giveaway-contacts/winner`;
  return apiRequestGet(token)()(apiUrl);
}

function giveawayContactsGetTotalWinners(token) {
  const apiUrl = `/giveaway-contacts/total-wins`;
  return apiRequestGet(token)()(apiUrl);
}

export {
  giveawayContactsGetRecentTotal,
  giveawayContactsGetTotal,
  giveawayContactsGetAll,
  giveawayContactsGetWinner,
  giveawayContactsGetTotalWinners,
};
