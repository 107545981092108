import React from 'react';
import PropTypes from 'prop-types';

import Styled from 'styled-components';

const Card = ({ children, inline, shadow, toolbar, ...props }) => {
  return (
    <Wrapper className="ps-card" inline={inline} shadow={shadow} {...props}>
      {toolbar && <div className="ps-card__toolbar">{toolbar}</div>}
      {children}
    </Wrapper>
  );
};

Card.propTypes = {
  inline: PropTypes.bool,
  shadow: PropTypes.number,
  toolbar: PropTypes.node,
  children: PropTypes.node,
};

Card.defaultProps = {
  inline: false,
  shadow: 3,
  toolbar: null,
};

const Wrapper = Styled.div`
  position: relative;
  background: white;
  padding: 24px;
  padding-top: ${({ toolbar }) => (toolbar ? '48px' : '24px')};
  border-radius: 4px;
  min-width: 100px;
  min-height: 100px;
  max-width: 100%;
  box-sizing: border-box;
  margin: 24px 24px 24px 0;
  box-shadow: 0 ${({ shadow }) => `${shadow}px`} ${({ shadow }) =>
  `${shadow * 2}px`} rgba(0,0,0,0.16), 0 ${({ shadow }) => `${shadow}px`} ${({
  shadow,
}) => `${shadow * 2}px`} rgba(0,0,0,0.23);
  display: ${({ inline }) => (inline ? 'inline-block' : 'block')};

  .ps-card__toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    right: 6px;
    top: 6px;
  }

  @media screen and (max-width: 480px) {
    margin-right: 0;
  }
`;

export default Card;
