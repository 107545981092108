/* Data takes shape of { key: value } */
function generateCSV(
  data,
  { includeHeaderRow = true, columnDelimiter = ',', rowDelimiter = '\n' } = {}
) {
  function escapeValue(value) {
    if (!value) {
      return;
    }
    const result = `"${value.replace(`"`, `""`)}"`;
    return result;
  }

  if (!data) {
    return;
  }

  const keys = Object.keys(data[0]);

  let result = '';

  if (includeHeaderRow) {
    const transformedKeys = Object.keys(data[0]).map(key => escapeValue(key));
    result += transformedKeys.join(columnDelimiter);
    result += rowDelimiter;
  }

  data.forEach(row => {
    keys.forEach(key => {
      result += escapeValue(row[key]) + columnDelimiter;
    });
    result += rowDelimiter;
  });

  return result;
}

function downloadCSV({ csv, filename = 'csv.csv' }) {
  if (!csv) {
    return;
  }

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  if (window && window.navigator && window.navigator.msSaveBlob) {
    // IE 10+
    window.navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection, Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style = 'visibility:hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export { generateCSV, downloadCSV };
